import React from "react"
import { Routes } from "../../data/routes"
import { PrimaryButton } from "../../components/button"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Schools",
  header: "Thanks for your interest in Partnering with Yup!",
  description: `A partnership manager will be in touch to schedule a demo soon`,
  headerImage: "purchase-complete.svg",
  headerImageAltText: "Two children typing on smartphones",
  headerCTALink: Routes.schools,
  headerCTAText: "Back to schools",
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-b731164a9948a780a5a346d9e637b053
  return (
    <div className="flex flex-col-reverse text-center md:text-left md:flex-row justify-around items-center mx-auto max-w-7xl">
      <div className="py-12 px-4">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
          {Content.header}
        </h1>
        <p className="mt-3 text-lg">{Content.description}</p>
        <div className="mt-4">
          <PrimaryButton
            text={Content.headerCTAText}
            href={Content.headerCTALink}
          />
        </div>
      </div>
      <div className="p-4">
        <Img
          className="sm:max-w-xl md:max-w-5xl lg:max-w-3xl w-full"
          src={Content.headerImage}
          alt={Content.headerImageAltText}
        />
      </div>
    </div>
  )
}

const SchoolsSuccess = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.schoolsSuccess}
      />
      <Header />
    </Layout>
  )
}

export default SchoolsSuccess
